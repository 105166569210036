import React, { useState } from 'react';
import ContactForm from './contactForm';


const ContactBlock = ({leadTag}) => {
  const [opened, setOpened] = useState(true)
  const handleOpenedChange = (childValue) => {
    setOpened(childValue);
  };

  return (
    <>

      {!opened ? (
        <div className="text-center">
          <svg className="mx-auto" width="133px" height="133px" viewBox="0 0 133 133" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="check-group" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <circle id="filled-circle" fill="#78B348" cx="66.5" cy="66.5" r="54.5"></circle>
              <circle id="white-circle" fill="#FFFFFF" cx="66.5" cy="66.5" r="55.5"></circle>
              <circle id="outline" stroke="#78B348" strokeWidth="4" cx="66.5" cy="66.5" r="54.5"></circle>
              <polyline id="check" stroke="#FFFFFF" strokeWidth="8" points="41 70 56 85 92 49"></polyline>
            </g>
          </svg>
          <div className="mt-6 text-5xl mb-6 font-black text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-pink-600">Thanks! <br/> We'll be in touch!</div>
        </div>
       
      ) : (
        <ContactForm leadTag={leadTag} onOpenedChange={handleOpenedChange} />
      )}


      <form name="contact" {...{netlify:"true"}} netlify-honeypot="bot-field" hidden>
        <input type="text" name="name" />
        <input type="text" name="company" />
        <input type="email" name="email" />
      </form>
    </>
  );
}

export default ContactBlock;