import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import ContactBlock from "../../components/contactBlock";
import { BackgroundImage } from "@mantine/core";

const bgBlueGreen = `url(/img/bg-green-blue-gradient.png)`;

export default class Index extends React.Component {

  render() {
    return (
      <Layout>
        <section>
        
<section className="pt-32 lg:pt-48 pb-32">
  <div className="container mx-auto px-3">
    <div className="flex flex-wrap lg:justify-between">
      <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
        <div className="mb-12 max-w-[570px] lg:mb-0">
          <span className="text-primary mb-4 block text-base font-semibold">
            Contact Us
          </span>
          <h2
            className="text-dark mb-6 text-[32px] font-bold uppercase sm:text-[40px] lg:text-[36px] xl:text-[40px]"
          >
            GET IN TOUCH WITH US
          </h2>
          <p className="text-body-color mb-9 text-base leading-relaxed">
            Don't hesitate to reach out to us, we are here to help you with any questions you may have. Got comments or Ideas? We'd love to hear them!
          </p>
          
          
          <div className="mb-8 flex w-full max-w-[370px]">
            <div
              className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]"
            >
              <svg
                width="28"
                height="19"
                viewBox="0 0 28 19"
                className="fill-current"
              >
                <path
                  d="M25.3636 0H2.63636C1.18182 0 0 1.16785 0 2.6052V16.3948C0 17.8322 1.18182 19 2.63636 19H25.3636C26.8182 19 28 17.8322 28 16.3948V2.6052C28 1.16785 26.8182 0 25.3636 0ZM25.3636 1.5721C25.5909 1.5721 25.7727 1.61702 25.9545 1.75177L14.6364 8.53428C14.2273 8.75886 13.7727 8.75886 13.3636 8.53428L2.04545 1.75177C2.22727 1.66194 2.40909 1.5721 2.63636 1.5721H25.3636ZM25.3636 17.383H2.63636C2.09091 17.383 1.59091 16.9338 1.59091 16.3499V3.32388L12.5 9.8818C12.9545 10.1513 13.4545 10.2861 13.9545 10.2861C14.4545 10.2861 14.9545 10.1513 15.4091 9.8818L26.3182 3.32388V16.3499C26.4091 16.9338 25.9091 17.383 25.3636 17.383Z"
                />
              </svg>
            </div>
            <div className="w-full">
              <h4 className="text-dark mb-1 text-xl font-bold">Email Address</h4>
              <p className="text-body-color text-base">info@stepwisehq.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-4 lg:w-1/2 xl:w-5/12" >
        <div className="rounded-[17px] bg-white bg-gradient-to-r from-blue-500 via-pink-500 to-purple-500 p-0.5">
          <div className="bg-white h-full rounded-2xl hover:shadow-2xl transition ease-in-out p-6">
            <ContactBlock leadTag="contact page"/>
          </div>
        
        <div>
           
            
    
            

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


        </section>
      </Layout>
    );
  }
}
